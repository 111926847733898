import destacamiento from "./destacamiento.js";
import listado_plazas from "./listado_plazas.js";
import permutas from "./permutas.js";
import renta from "./renta.js";
import amonestacion from "./amonestacion.js";
export default {
    namespaced: true,
    state: {
       
    },
    getters: {
       
    },
    mutations: {
        
    },
    actions: {
      
    },
    modules: {
        amonestacion,
       destacamiento,
       permutas,
       listado_plazas,
       renta
    }
};


import { http_client } from "@/plugins/http_client";

const obtenerInformacionEmpleado = async ( numeroDocumento, idSolicitud, params = {} ) =>
	await http_client(`/api/empleado-detalles/${numeroDocumento}/${idSolicitud}`, params, "get");

const obtenerInformacionEmpleadoTrasladoEjecutado = async ( numeroDocumento, idSolicitud, tipoMovimiento, params = {} ) =>
	await http_client(`/api/traslado/empleado-detalles/${numeroDocumento}/${idSolicitud}/${tipoMovimiento}`, params, "get");

const obtenerDetallesPlaza = async ( params = {} ) =>{
	
	return await http_client("/api/detalle-plaza", params, "get");
}

const crearDetallesPlazaPersona = async ( params = {} ) =>
    await http_client("/api/destacamiento/detalle-solicitud-persona", params, "post");

const crearDetallesPlazaPersonaTraslado = async ( params = {} ) =>
    await http_client("/api/traslado/detalle-solicitud-persona", params, "post");

const editarDetallesPlazaPersona = async ( idItem, params = {} ) =>
    await http_client(`/api/destacamiento/detalle-solicitud-persona/editar/${idItem}`, params, "put");

const editarDetallesPlazaPersonaTraslado = async ( idItem, params = {} ) =>
    await http_client(`/api/traslado/detalle-solicitud-persona/editar/${idItem}`, params, "put");

const obtenerDetallePersona = async (idSolicitud,fase,bandera,page, params = {} ) =>
	await http_client(`/api/destacamiento/listar-personas/${idSolicitud}/${fase}/${bandera}?page=${page}`,params, "get");

const obtenerDetallePersonaTraslado = async (idSolicitud,fase,bandera,page, params = {} ) =>
	await http_client(`/api/traslado/listar-personas/${idSolicitud}/${fase}/${bandera}?page=${page}`,params, "get");
const personas = async (idSolicitud,fase,page, params = {} ) =>
	await http_client(`/api/destacamiento/listarPersonas/${idSolicitud}?page=${page}`,params, "get");
export default {
	obtenerInformacionEmpleado,
	obtenerDetallesPlaza,
	crearDetallesPlazaPersona,
	obtenerDetallePersona,
	editarDetallesPlazaPersona,
	personas,
	crearDetallesPlazaPersonaTraslado,
	obtenerDetallePersonaTraslado,
	editarDetallesPlazaPersonaTraslado,
	obtenerInformacionEmpleadoTrasladoEjecutado
}
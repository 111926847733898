export default [
  {
    path: 'acciones-movimientos/amonestacion',
    name: 'amonestacion_personal',
    component: () => import(/* webpackChunkName: "ascenso" */ './listView.vue'),
  },
  {
    path: "acciones-movimientos/amonestacion/solicitud",
    name: "amonestacion_solicitud",
    component: () =>
      import( /* webpackChunkName: "amonestacion_solicitud" */ "@/views/acciones-movimientos/amonestacion/components/infoSolicitanteComponent.vue"),
  },
  {
    path: "acciones-movimientos/amonestacion/solicitud/:id/:esResolucion?",
    name: "amonestacion_solicitud_ver",
    component: () =>
      import( /* webpackChunkName: "amonestacion_solicitud" */ "@/views/acciones-movimientos/amonestacion/solicitudView.vue"),
  },
  {
    path: "acciones-movimientos/amonestacion/solicitud/editar/:id",
    name: "amonestacion_solicitud_editar",
    component: () =>
      import( /* webpackChunkName: "amonestacion_solicitud" */ "@/views/acciones-movimientos/amonestacion/solicitudView.vue"),
  },
  {
    path: 'amonestacion/plantillas',
    name: 'amonestacion-plantillas',
    component: () => import('@/views/acciones-movimientos/amonestacion/plantillas/listView.vue')
  },
  {
    path: 'amonestacion/plantilla/crear/:id_tipo_amonestacion?',
    name: 'amonestacion-plantilla-crear',
    component: () => import('@/views/acciones-movimientos/amonestacion/plantillas/createView.vue')
  },
  {
    path: 'amonestacion/plantilla/tipo/:id_tipo_amonestacion',
    name: 'amonestacion-plantilla-historial',
    component: () => import('@/views/acciones-movimientos/amonestacion/plantillas/historialView.vue')
  }
]
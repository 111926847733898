import { http_client } from "@/plugins/http_client";
const url = 'api/v1/ascenso'
const listadoSolicitudAscenso = async ( params = {} ) => await http_client(`${url}`, params);
const detallePlaza = async ( params = {} ) => await http_client(`api/detalle-plaza`, params);
const enviarSolicitudAscenso = async ( params = {} ) => await http_client(`${url}`, params, 'post');
const SolicitudAscenso = async ( id , params = {} ) => await http_client(`${url}/${id}`, params);
const actividades = async ( params = {} ) => await http_client(`api/listar-actividad`, params);
const formacion = async ( params = {} ) => await http_client(`api/listar-formacion`, params);
const conocimiento = async ( params = {} ) => await http_client(`api/listar-conocimiento`, params);
const experiencia = async ( params = {} ) => await http_client(`api/listar-experiencia`, params);
const capacidad = async ( params = {} ) => await http_client(`api/listar-capacidad`, params);
const aspecto = async ( params = {} ) => await http_client(`api/listar-aspecto`, params);
const cargos = async (params = {})=> await http_client(`api/filtrar-cargo`, params);
const listadoAprobaciones = async ( codigo_fase, params = {} ) => await http_client(`${url}/aprobacion/${codigo_fase}`, params);
const listUnidadPresupuestaria = async ( params = {} ) => await http_client(`${url}/unidad-presupuestaria`, params);
const listLineaTrabajo = async ( params = {} ) => await http_client(`${url}/linea-trabajo`, params);
const listPlazas = async ( params = {} ) => await http_client(`${url}/plaza`, params);
const updateSolicitudAscenso = async ( id , params = {} ) => await http_client(`${url}/${id}`, params, 'put');
const evaluarSolicitud =  async (id_solicitud, body = {})=>await http_client(`${url}/evaluar/${id_solicitud}`, body, 'post');
const reiniciarSolicitud =  async (id_solicitud, body = {})=>await http_client(`${url}/reiniciar-solicitud/${id_solicitud}`, body, 'put');
const DetalleActividadescargo = async (id_empleado, id_detalle_plaza, params = {} ) => await http_client(`${url}/detalle/${id_empleado}/${id_detalle_plaza}`, params);
const obtenerInformacionEmpleado = async ( numeroDocumento, params = {} ) => await http_client(`/api/v2/ascenso/empleado-detalles/${numeroDocumento}`, params, "get");
const getactividades = async ( numeroDocumento, params = {} ) => await http_client(`/api/empleado-detalles/${numeroDocumento}`, params, "get");
const obtenerDetalleCargosPrincipal = async (params = {} ) => await http_client(`api/v1/empleado-puesto/funciones`, params, "get");
const obtenerDetalleObservacion = async (idSolicitud, params ={}) => await http_client(`api/v1/ascenso/observaciones/${idSolicitud}`,params,"get")
const detallePlazaSubpartida = async ( params = {} ) => await http_client(`${url}/detalle-plaza-subpartidas`, params);
const crearDocumentos = async (params = {}) => await http_client("/api/v1/ascenso/crear-documento", params, "post");




export default {
	listadoSolicitudAscenso,
	detallePlaza,
	enviarSolicitudAscenso,
	SolicitudAscenso,
	actividades,
	formacion,
	conocimiento,
	experiencia,
	capacidad,
	aspecto,
	cargos,
	listadoAprobaciones,
	listUnidadPresupuestaria,
	listLineaTrabajo,
	listPlazas,
	updateSolicitudAscenso,
	evaluarSolicitud,
	reiniciarSolicitud,
	DetalleActividadescargo,
	obtenerInformacionEmpleado,
	obtenerDetalleCargosPrincipal,
	obtenerDetalleObservacion,
	detallePlazaSubpartida,
	crearDocumentos
	
}
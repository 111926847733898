export default [
    {
      path: "acciones-movimientos/despidos",
      name: "despido_personal",
      component: () =>
        import( /* webpackChunkName: "despido_personal" */ "@/views/acciones-movimientos/despidos/index.vue" ),
    },
    {
      path: "acciones-movimientos/despidos/solicitud",
      name: "despido_personal_solicitud",
      component: () =>
        import( /* webpackChunkName: "despido_personal_solicitud" */ "@/views/acciones-movimientos/despidos/views/solicitudDespido.vue" ),
    },
    {
      path: "acciones-movimientos/despidos/solicitud/detalle/:idSolicitud/:isTribunal?",
      name: "despido_personal_solicitud_ver",
      component: () =>
        import( /* webpackChunkName: "despido_personal_solicitud_ver" */ "@/views/acciones-movimientos/despidos/views/detalleSolicitud.vue" ),
      props: true
    },
    {
      path: "acciones-movimientos/despidos/solicitud/editar/:idSolicitud/:type?",
      name: "despido_personal_solicitud_editar",
      component: () =>
        import( /* webpackChunkName: "despido_personal_solicitud_editar" */ "@/views/acciones-movimientos/despidos/views/editarSolicitud.vue" ),
    },


    // Version de las rutas para comision
    {
      path: "comision/despidos",
      name: "despido_personal_comision",
      component: () =>
        import( /* webpackChunkName: "despido_personal_comision" */ "@/views/acciones-movimientos/despidos/index.vue" ),
    },
    // {
    //   path: "comision/despidos/solicitud",
    //   name: "despido_personal_solicitud_comision",
    //   component: () =>
    //     import( /* webpackChunkName: "despido_personal_solicitud_comision" */ "@/views/acciones-movimientos/despidos/views/solicitudDespido.vue" ),
    // },
    {
      path: "comision/despidos/solicitud/detalle/:idSolicitud/:isTribunal?",
      name: "despido_personal_solicitud_ver_comision",
      component: () =>
        import( /* webpackChunkName: "despido_personal_solicitud_ver_comision" */ "@/views/acciones-movimientos/despidos/views/detalleSolicitud.vue" ),
      props: true
    },
    // {
    //   path: "comision/despidos/solicitud/editar/:idSolicitud/:type?",
    //   name: "despido_personal_solicitud_editar_comision",
    //   component: () =>
    //     import( /* webpackChunkName: "despido_personal_solicitud_editar_comision" */ "@/views/acciones-movimientos/despidos/views/editarSolicitud.vue" ),
    // },
]
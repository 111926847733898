<template>
  <div>
    <v-btn
      v-bind="extraProps"
      :width="$vuetify.breakpoint.smAndDown ? '100%' : widthSize"
      :disabled="disabled"
      :outlined="outlined"
      :color="colors"
      class="rounded-xl"
      :class="extrClass"
      style="text-transform: none"
      :rounded="rounded"
      :style="buttonStyle"
      @click="handleButton"
      :large="large"
      :text="text"
      :ripple="ripple"
      :plain="plain"
      :loading="loading"
    >
      {{ title }}
      <v-icon :class="iconClass" v-if="icon !== ''">{{ icon }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "buttonComponent",
  props: {
    title: {
      type: String,
    },
    colors: {
      type: String,
      default: "#1C1E4D",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    widthSize: {
      type: String,
      default: "100px",
    },
    extraProps: {
      type: Object,
      default: () => {},
    },
    extrClass: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    iconClass: {
      type: String,
      default: "position-absolute-right",
    },
    large: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    ripple: {
      type: Boolean,
      default: true,
    },
    plain: {
      type: Boolean,
      default: false,
    },
    rounded: {
		type: Boolean,
        default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonStyle() {
      return this.outlined ? {} : { color: "white" };
    },
  },
  methods: {
    handleButton() {
      this.$emit("btnAccion");
    },
  },
  // updated() {
  //     console.log(this.disabled);
  // },
};
</script>

<style>
.position-absolute-left {
  position: absolute !important;
  left: 0 !important;
}

.position-absolute-right {
  position: absolute !important;
  right: 0 !important;
}
</style>
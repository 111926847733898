import jwtDecode from "jwt-decode";

export default {
    namespaced: true,
    state: {
        institucionData:null,
        establecimientoData:null,
        unidadData:null,
        solicitudData:{},
        archivoEjecutivo: false,
        mostrarSolventar:false,
        esEditable: false,
        rrhhResolucion: false,
        aceptar: false,
        observar: false,
        esResolucionTitular: false,
        mostrarArchivoEjecutivo: false,
    },

    mutations: {
        setInstitucionData(state,payload) {
            state.institucionData = payload
        },
        setEstablecimientoData(state,payload) {
            state.establecimientoData = payload
        },
        setUnidadData(state,payload) {
            state.unidadData = payload
        },
        setSolicitudData(state,payload) {
            state.solicitudData = payload
        },
        setArchivoEjecutivo(state,payload) {
            state.archivoEjecutivo = payload
        },
        setMostrarSolventar(state,payload) {
            state.mostrarSolventar = payload
        },
        setEsEditable(state,payload) {
            state.esEditable = payload
        },
        setRrhhResolucion(state,payload) {
            state.rrhhResolucion = payload
        },
        setAceptar(state,payload) {
            state.aceptar = payload
        },
        setObservar(state,payload) {
            state.observar = payload
        },
        setEsResolucionTitular(state,payload) {
            state.esResolucionTitular = payload
        },
        setMostrarArchivoEjecutivo(state,payload) {
            state.mostrarArchivoEjecutivo = payload
        },

    },
    actions: {
        getSolicitanteData:({commit}, dataSolicitante = null)=> {
            if (dataSolicitante) {
                commit('setInstitucionData',dataSolicitante.institucion);
                commit('setEstablecimientoData',dataSolicitante.establecimiento);
                commit('setUnidadData',dataSolicitante.unidad);
            }
            else{
                const tokenData = jwtDecode(localStorage.getItem('token'));

                commit('setInstitucionData',tokenData?.puesto?.institucion.nombre);
                commit('setEstablecimientoData',tokenData?.puesto?.establecimiento.nombre);
                commit('setUnidadData',tokenData?.puesto?.unidad_organizativa.nombre);
            }

        }
    },
};

import { http_client } from "@/plugins/http_client"

const baseUrl = '/api/amonestacion/plantillas'

const listarPlantillas = async (params = {}) => await http_client(baseUrl, params, 'get')
const inhabilitarPlantilla = async (id) => await http_client(`${baseUrl}/${id}`, {}, 'delete')
const obtenerHistorialPlantillasPorTipo = async (tipo) => await http_client(`${baseUrl}/tipo/${tipo}`, {}, 'get')
const obtenerEtiquetas = async () => await http_client(`${baseUrl}/etiquetas`, {}, 'get')
const catalogoTipoAmonestacion = async () => await http_client('/api/amonestacion/tipo-amonestacion', {}, 'get')
const guardarPlantilla = async (data) => await http_client(baseUrl, data, 'post')
const guardarImagen = async (body = {}) => await http_client(`${baseUrl}/imagen`, body, 'post', {
  headers: { "content-type": "multipart/form-data" },
})
const previsualizarPlantilla = async (params = {}) => await http_client(`${baseUrl}/previsualizar`, params, "post")
const visualizarPlantilla = async (idPlantilla) => await http_client(`${baseUrl}/${idPlantilla}/visualizar`, {}, "get");
const obtenerPlantillaActiva = async (idPlantilla) => await http_client(`${baseUrl}/${idPlantilla}`, {}, "get");

export default {
  listarPlantillas,
  inhabilitarPlantilla,
  obtenerHistorialPlantillasPorTipo,
  obtenerEtiquetas,
  catalogoTipoAmonestacion,
  guardarPlantilla,
  guardarImagen,
  previsualizarPlantilla,
  visualizarPlantilla,
  obtenerPlantillaActiva,
}
<template>
    <v-dialog v-model="dialogEmpleado" persistent max-width="1200px" max-height=" 1200px">
        <v-card class="scroll">
            <v-toolbar dark color="bluePrimary" style="border-radius: 10px 10px 0px 0px;">

                <v-toolbar-title>Información personal</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon dark @click="closeDialog()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
                <v-row>
                <v-col cols="12" md="3" class="d-flex align-center justify-center" v-if="showImage()">
                    <v-avatar height="200" width="200" class="xs12 sm6 md4 lg3 xl2">
                        <v-img
                            src="@/assets/img/default_user.png"
                            alt="Foto de perfil"
                            style="object-fit: cover !important; object-position: top !important;"
                        />
                    </v-avatar>
                </v-col>
                <v-col cols="12" md="9">
                        <v-card-subtitle class="mx-0 px-0 font-weight-black text-h5 mb-5 bluePrimary--text">
                            {{ getFullName?.toUpperCase() }}
                        </v-card-subtitle>
                    <v-row>
                    <v-col cols="12" class="d-flex justify-space-between">
                        <v-row>
                        <v-col cols="6" md="3" class="appdetalleplaza">
                            <p>Tipo documento</p>
                            <p>{{ dataObject.persona?.documento_persona[0]?.tipo_documento?.nombre?.toUpperCase() }}</p>
                        </v-col>
                        <v-col cols="6" md="3" class="appdetalleplaza">
                            <p>N° documento</p>
                            <p>{{ dataObject.persona?.documento_persona[0]?.numero }}</p>
                        </v-col>
                        <v-col cols="6" md="3" class="appdetalleplaza">
                            <p>Unidad organizativa</p>
                            <p>{{ dataObject.empleado_puesto[0]?.unidad_organizativa?.nombre?.toUpperCase() }}</p>
                        </v-col>
                        <v-col cols="6" md="3" class="appdetalleplaza">
                            <p>Plaza</p>
                            <p> {{ dataObject.empleado_puesto[0]?.detalle_plaza?.detalle_plaza.nombre?.toUpperCase() }}</p>
                        </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-space-between">
                    <v-row>
                        <v-col cols="6" md="3" class="appdetalleplaza">
                        <p>Fecha de contratación</p>
                        <p>{{ formatDate(dataObject?.fecha_ingreso) }}</p>										
                        </v-col>
                        <v-col cols="6" md="3" class="appdetalleplaza">
                        <p>Modalidad de contratación</p>
                        <p> {{ dataObject?.empleado_puesto[0]?.detalle_plaza?.sistema_pago?.nombre?.toUpperCase() }}</p>
                        </v-col>
                        <v-col cols="6" md="3" class="appdetalleplaza">
                        <p>Salario real</p>
                        <p>{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.salario_minimo  }}</p>
                        </v-col>
                        <v-col cols="6" md="3" class="appdetalleplaza" >
                        <p>Cargo</p>
                        <p>{{ dataObject.empleado_puesto[0]?.cargo_funcional?.nombre?.toUpperCase() }}</p>
                        </v-col>
                    </v-row>
                    </v-col>
                    <v-col>
                    <v-row>
                        <v-col cols="6" md="3" class="appdetalleplaza">
                        <p>Tiempo de servicio</p>
                        <p>{{ dataObject?.tiempo_servicio?.toUpperCase()  }}</p>
                        </v-col>
                        <v-col cols="6" md="3" class="appdetalleplaza">
                        <p>Estado de puesto</p>
                        <p>{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.estado_plaza?.activo ? 'ACTIVO' : 'INACTIVO' }}</p>
                        </v-col>
                        <v-col cols="6" md="3" class="appdetalleplaza">
                        <p>Estado persona</p>
                        <p>{{ dataObject?.persona?.activo ? 'ACTIVO' : 'INACTIVO' }}</p>
                        </v-col>
                    </v-row>
                    </v-col>
                    </v-row>
                </v-col>
                </v-row>

                <v-card class="mt-5 rounded-t-xl">
                    <v-toolbar dark color="bluePrimary" class="scroll" style="border-radius: 10px 10px 0px 0px;">
                        <v-toolbar-title>Datos de la plaza</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text class="pt-5">
                        <v-row>
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="6" md="3" class="appdetalleplaza">
                                    <p>Denominación de la plaza</p>
                                    <p>{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.detalle_plaza?.nombre?.toUpperCase() }}</p>
                                </v-col>
                                <v-col cols="6" md="3" class="appdetalleplaza">
                                    <p>Institución</p>
                                    <p>{{ dataObject?.empleado_puesto[0]?.unidad_organizativa?.establecimiento?.institucion?.nombre?.toUpperCase() }}</p>
                                </v-col>
                                <v-col cols="6" md="3" class="appdetalleplaza">
                                    <p>Número de horas</p>
                                    <p>{{ dataObject?.empleado_puesto[0]?.horas_plaza }}</p>
                                </v-col>
                                <v-col cols="6" md="3" class="appdetalleplaza">
                                    <p>Cifrado presupuestario</p>
                                    <p>{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.cifrado?.codigo }}</p>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="6" md="3" class="appdetalleplaza">
                                    <p>Unidad presupuestaria</p>
                                    <p>{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.cifrado?.unidad_presupuestaria?.codigo }}</p>
                                </v-col>
                                <v-col cols="6" md="3" class="appdetalleplaza">
                                    <p>Línea de trabajo</p>
                                    <p>{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.cifrado?.codigo_linea_trabajo }}</p>
                                </v-col>
                                <v-col cols="6" md="3" class="appdetalleplaza">
                                    <p>Partida</p>
                                    <p>{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.partida }}</p>
                                </v-col>
                                <v-col cols="6" md="3" class="appdetalleplaza">
                                    <p>Subpartida</p>
                                    <p>{{ dataObject?.empleado_puesto[0]?.sub_numero }}</p>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="6" md="3" class="appdetalleplaza">
                                    <p>Tipo de financiamiento</p>
                                    <p>{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.tipo_financiamiento?.nombre?.toUpperCase() }}</p>
                                </v-col>
                                <v-col cols="6" md="3" class="appdetalleplaza">
                                    <p>Presupuesto que financia</p>
                                    <p>{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.presupuesto_financia?.nombre?.toUpperCase() }}</p>
                                </v-col>
                                <v-col cols="6" md="3" class="appdetalleplaza">
                                    <p>Salario base</p>
                                    <p>{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.salario_minimo ? '$ '+ dataObject?.empleado_puesto[0]?.detalle_plaza?.salario_minimo : '' }}</p>
                                </v-col>
                                <v-col cols="6" md="3" class="appdetalleplaza">
                                    <p>Salario máximo</p>
                                    <p>{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.salario_maximo ? '$ ' + dataObject?.empleado_puesto[0]?.detalle_plaza?.salario_maximo : '' }}</p>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="6" md="3" class="appdetalleplaza">
                                    <p>Especifico de gasto</p>
                                    <p>{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.especifico_gasto?.codigo }}</p>
                                </v-col>
                                <v-col cols="6" md="3" class="appdetalleplaza">
                                    <p>Estado de plaza</p>
                                    <p>{{ dataObject?.empleado_puesto[0]?.detalle_plaza?.activo ? "ACTIVO" :  "INACTIVO" }}</p>
                                </v-col>
                                <v-col cols="6" md="3" class="appdetalleplaza"></v-col>
                                            <v-col cols="6" md="3" class="appdetalleplaza"></v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    </v-card-text>
                </v-card>
            <div class=" d-flex justify-center">
                <v-btn color="bluePrimary" dark outlined rounded class="text-capitalize ma-2" @click="closeDialog()">
                    Cerrar
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: "detalleEmpleado",
    props: {
        dataObject: {
            type: Object,
            required: true,
            default: () => ({})
        },
        closeDialog: {
            type: Function,
            required: true
        },
        dialogEmpleado: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
        };
    },
    methods: {
        // formatear fecha en dia/mes/año
        formatDate(date) {
            if (date) {
                const [year, month, day] = date.split("-");
                return `${day}/${month}/${year}`;
            }else{
                return 'Sin fecha';
            }
        },
        // mostrar imagen cuando no sea xs y md
        showImage() {
            return !this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.md;
        },
    },
    mounted() {
        // mounted
    },
    computed: {
        getFullName() {
            let fullName = "";
            if (this.dataObject?.persona?.primer_nombre) {
                fullName += this.dataObject?.persona?.primer_nombre;
            }
            if (this.dataObject?.persona?.segundo_nombre) {
                fullName += " " + this.dataObject?.persona?.segundo_nombre;
            }
            if (this.dataObject?.persona?.primer_apellido) {
                fullName += " " + this.dataObject?.persona?.primer_apellido;
            }
            if (this.dataObject?.persona?.segundo_apellido) {
                fullName += " " + this.dataObject?.persona?.segundo_apellido;
            }
            return fullName.toUpperCase();
        },
    },
    created() {
    },
};
</script>
<style scope>
/* styles */

.appdetalleplaza > p {
	margin: 0;
    color: gray;
}
.appdetalleplaza > p:last-child {
	font-weight: bold;
	font-size: 1rem;
}

.no-padding {
    margin-bottom: 0 !important;
    padding: 0 !important;
}

.wrapper {
    display: flex;
    margin: 10px;
}

.one {
    flex: 1;
    background-size: cover;
}

.two {
    flex: 6;
}

.scroll {
    overflow: hidden;
}
.v-dialog {
    border-radius: 10px;
    margin: 24px;
    overflow-y: auto;
    pointer-events: auto;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%;
    z-index: inherit;
    outline: none;
    /* box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); */
}
</style>

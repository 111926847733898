import Vue from "vue";
import VueRouter from "vue-router";
import jwtDecode from "jwt-decode";
import moment from "moment-timezone";
import rolesRouter from "../views/admin/roles/rolesRouter";
import profilesRouter from "../views/admin/profiles/profilesRouter";
import usersRouter from "../views/admin/users/userRouter";
import pathsRouter from "../views/admin/paths/pathRouter";
import ingresosRoutes from "../views/catalogos/ingresos/router";
import deduccionesRoutes from "../views/catalogos/deducciones/router";
import planillaRoutes from "../views/planilla/router";
import solicitudFondosRoutes from "../views/solicitudFondos/router";
import catalogosGeneralesRouter from "../views/catalogos/catalogosRouter";
import estructuraRouter from "../views/estructura/estructuraRouter";
import configuracionParametrosSistemaRoutes from "../views/configuracionParametrosSistema/router";
import nomina from "../views/nomina/router";
import PuestoUnidadRouter from "../views/puestoUnidad/puestoUnidadRouter";
import organigramaInstitucional from "../views/organigrama/router";
import accionesMovimientosRouter from "../views/acciones-movimientos/accionesMovimientosRouter";
import renunciasRouter from "../views/renuncias/renunciasRouter";
import reportesRouter from "../views/reportes/reportesRouter";
import plazasRouter from '../views/plazas/plazasroutes';
import agrupadoresPlazasRoutes from '../../src/views/catalogos/catalogoAgrupadoresPlaza/router/index';
import comisionRouter from '../views/comision/router';
import gestionRoles from '../views/gestionRoles/router';
import refrendaRouter from '../views/refrenda/refrendaRoutes';
import exoneracionMarcacionRoutes from "../views/acciones-movimientos/exoneracion_marcacion/routes/exoneracionMarcacionRoutes";
import amonestacionesRouter from "../views/acciones-movimientos/amonestacion/router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "menu",
    component: () =>
      import(/* webpackChunkName: "profiles" */ "../views/menu"),
  },
  {
    path: "/",

    component: () => import(/* webpackChunkName: "layout" */ "../views/layout"),

    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () =>
          import(/* webpackChunkName: "profiles" */ "../views/dashboard"),
      },
      {
        path: "profile",
        name: "profile",
        component: () =>
          import(/* webpackChunkName: "profiles" */ "../views/profile"),
      },
      ...rolesRouter,
      ...profilesRouter,
      ...usersRouter,
      ...pathsRouter,
      ...ingresosRoutes,
      ...deduccionesRoutes,
      ...planillaRoutes,
      ...solicitudFondosRoutes,
      ...catalogosGeneralesRouter,
      ...estructuraRouter,
      ...configuracionParametrosSistemaRoutes,
      ...nomina,
      ...PuestoUnidadRouter,
      ...organigramaInstitucional,
      ...accionesMovimientosRouter,
      ...renunciasRouter,
      ...reportesRouter,
      ...plazasRouter,
      ...agrupadoresPlazasRoutes,
      ...comisionRouter,
      ...gestionRoles,
      ...refrendaRouter,
      ...exoneracionMarcacionRoutes,
      ...amonestacionesRouter,
      {
        path: "/security",
        name: "security",
        component: () =>
          import(/* webpackChunkName: "qr" */ "../views/auth/security"),
      },
    ],

  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/login"),
  },
  {
    path: "/seleccionar-puestos",
    name: "seleccionar-puestos",
    component: () =>
      import(/* webpackChunkName: "seleccionar-puesto" */ "../views/auth/puestos"),
  },
  {
    path: "/forbidden",
    name: "Forbidden",
    component: () =>
      import(/* webpackChunkName: "forbidden" */ "../views/forbidden"),
  },
  {
    path: "/errors/:uuid",
    name: "Errors",
    component: () =>
      import(/* webpackChunkName: "forbidden" */ "../views/errors"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("token");

  if (to.path === "/") {
    next();
    return;
  }

  const noAuth = ["login", "verifyMail", "reset-password", "forgot-password", "dashboard"]; 
  if (to.name !== 'steps') {
    localStorage.removeItem('stepNumber')
  }
  if (!token && !noAuth.includes(to.name)) {
    next("/login");
    return;
  } else {
    if (!token && noAuth.includes(to.name)) {
      next();
      return;
    }
    
    const userDecode = jwtDecode(token);
    let expiracion = moment(new Date(userDecode.exp * 1000));
    let actual = moment(new Date(Date.now()));
    if (
      actual.diff(expiracion, "days") > 0 ||
      actual.diff(expiracion, "hours") > 0 ||
      actual.diff(expiracion, "minutes") > 0
    ) {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      next("/login");
    } else {
      next();
    }
  }
});

export default router;
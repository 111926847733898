import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "../assets/scss/index.scss";
import es from "vuetify/lib/locale/es";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { es },
    current: "es",
  },
  theme: {
    themes: {
      //Se definen colores globales para el tema claro
      light: {
        blueMinsal: "#175EFB",
        blueGrayMinsal: "#6A83BE",
        blueDarkMinsal: "#2D52A8",
        bgMinsal: "#F4F7FD",
        bgWhite: "#FFFFFF",
        primary: "#313945",
        skyBlueRRHH: "#30a4f1",
        iconsColor: "#1C1B1F",
        bluePrimary: "#1C1E4D",
        whiteSecondary: "F5F9FF",
        greenMinsal: "#776508",
        lightGreen: '#45C4B0',
        yellowMinsal: "#FFC107",
        yellowNotificado: "#EAE089",
        yellowButton: "#F3A723",
        greenMinsal: "#776508",
        redButton: "#EF5350",
        cyanTribunal: "#15CFE8",
        cyanAutorizado: "#0F91D2",
        greenRestituido: "#4CAF50",
        redDestituido: "#F44336",
        orangeEnProceso: "#FF9721",
        grayText: "#E2E2E2",
        grayLight: "#F2F2F2",
        estadoColor1: "#C5C8CF",
        estadoColor2: "#13678A",
        whiteGreen: "#E1EFD7",
        whiteRed: "#FFC6C6",
        bgFooterMenu: '#2E2F5B',
        greenFinalizado: "#2AE5A2",
        estadoVigenteTarifa: '#45C4B0',
        estadoFinalizadoTarifa: '#043E54',
        estadoProgramada: 'e9b241',
        estadoFinalizadoExoneracion: "#4670C4",
        estadoPendienteExoneracion: "#C8BABD",
        estadoEjecucionExoneracion: "#D5CF1C"
      },
      dark: {
        bgMinsal: "#2f3032",
        blueMinsal: "#175EFB",
        blueGrayMinsal: "#6A83BE",
        blueDarkMinsal: "#2D52A8",
        primary: "#313945",
        bluePrimary: "#1C1E4D",
        yellowMinsal: "#FFC107",
        yellowNotificado: "#EAE089",
        greenMinsal: "#776508",
        redButton: "#EF5350",
        cyanTribunal: "#15CFE8",
        greenRestituido: "#4CAF50",
        redDestituido: "#F44336",
        grayText: "#697182",
        estadoColor1: "#C5C8CF",
        estadoColor2: "#13678A",
        bgFooterMenu: '#2E2F5B',
        estadoVigenteTarifa: '#45C4B0',
        estadoFinalizadoTarifa: '#043E54',
        estadoProgramada: 'e9b241'
        // primary: {
        //     base: colors.purple.base,
        // }
      },
    },
  },
});

import { http_client } from "../../../../plugins/http_client";

const crearSolicitudAmonestacion = async ( params = {} ) =>
    await http_client("/api/amonestacion/cambiar-fase-solicitud", params, "post");


const observarDenegarSolicitud = async (params = {}) =>
    await http_client("/api/amonestacion/procesar-solicitud", params, "post");

const obtenerSolicitudAmonestacion = async ( params = {}) =>
    await http_client(`/api/amonestacion/solicitud-amonestacion`, params, "get");

const getTipoAmonestacion = async (params = {}) =>
    await http_client("/api/amonestacion/tipo-amonestacion", params, "get");

const getInformacionEmpleado = async (numDocumento, params = {}) =>
    await http_client(`/api/amonestacion/empleado-plazas/${numDocumento}`, params, "get");

const getFaltasEmpleados = async ( params = {} ) =>
    await http_client(`/api/amonestacion/ctl/faltas_empleados`, params, "get");


//Histoiral de amonestaciones

const getListadoHistorialAmonestaciones = async ( idSolicitud, params = {}) => await http_client(`/api/amonestacion/listar-historial-amonestaciones-solicitud/${idSolicitud}`, params, "get")

const getHistoricoEmpleado = async (idEmpleadoPuesto, params = {}) => await http_client(`/api/amonestacion/listar-historial-amonestaciones/${idEmpleadoPuesto}`, params, "get")

//Documentos general

const postDocumentos = async (params) => await http_client(`api/amonestacion/guardar-documento
`, params, 'post', { headers: { "content-type": "multipart/form-data" },})

const getDocumentos = async (id_solicitud, page, params) => await http_client(`/api/amonestacion/obtener-documento/${id_solicitud}?page=${page}`, params, 'get')   

const deleteDocumentos = async (id, filtros) => await http_client(`/api/amonestacion/eliminar-documento/${id}`, filtros, 'delete')

const verPdfDocumentos = async (id) => await http_client(`/api/amonestacion/ver-documento/${id}`)

const descargarDocumentos = async (id) => await http_client(`/api/amonestacion/descargar-documento/${id}`)

//Documento ejecutivo

const postDocumentoEjecutivo = async (params) => await http_client(`api/amonestacion/guardar-documento-titular`, params, 'post', { headers: { "content-type": "multipart/form-data" },})

const getDocumentoEjecutivo = async (id_solicitud) => await http_client(`/api/amonestacion/obtener-documento-titular/${id_solicitud}`)

const deleteDocumentoEjecutivo = async (id, filtros) => await http_client(`/api/amonestacion/eliminar-documento-titular/${id}`, filtros, 'delete')

const verPdfDocumentoEjecutivo = async (id) => await http_client(`/api/amonestacion/ver-documento-titular/${id}`)

const descargarDocumentoEjecutivo = async (id) => await http_client(`/api/amonestacion/descargar-documento-titular/${id}`)

const getAmonestaciones = async (params = {}) => await http_client('/api/amonestacion/listar_amonestaciones', params, "get")
const getIndexEstados = async (params = {}) => await http_client('/api/amonestacion/index-estados', params, "get")

/* Descargar Documentos */
const descargarDocumentoTitular = async (idSolicitud, params = {}) => await http_client(`/api/amonestacion/descargar-documento-titular/${idSolicitud}`, params, "get");
const obtenerAcuerdoTitular = async (idSolicitud) => await http_client(`/api/amonestacion/${idSolicitud}/acuerdo`, {}, "get");

export default {
    getInformacionEmpleado,
    obtenerSolicitudAmonestacion,
    crearSolicitudAmonestacion,
    getTipoAmonestacion,
    getFaltasEmpleados,
    postDocumentos,
    getDocumentos,
    deleteDocumentos,
    verPdfDocumentos,
    descargarDocumentos,
    postDocumentoEjecutivo,
    getDocumentoEjecutivo,
    deleteDocumentoEjecutivo,
    verPdfDocumentoEjecutivo,
    descargarDocumentoEjecutivo,
    observarDenegarSolicitud,
    getInformacionEmpleado,
    getAmonestaciones,
    getIndexEstados,
    descargarDocumentoTitular,
    getListadoHistorialAmonestaciones,
    getHistoricoEmpleado,
    obtenerAcuerdoTitular
}
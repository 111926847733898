import { http_client} from "@/plugins/http_client";

const URL = '/api/v1/despidos'


// Listados generales

const listadoSolicitudes = async (params) => {
    return await http_client(`${URL}`, params, 'get');
}

const listadoEsperaApelacion = async (params) => {
    return await http_client(`${URL}/index-espera-apelacion`, params, 'get');
}

const listadoSolicitudesApelacion = async (params) => {
    return await http_client(`${URL}/tab-apelaciones`, params, 'get');
}

const listadoEnTribunal = async (params) => {
    return await http_client(`${URL}/tab-en-tribunal`, params, 'get');
}


// Listados para comision

const listadoSolicitudesComision = async (params) => {
    return await http_client(`${URL}/index-comision`, params, 'get');
}

const listadoEsperaApelacionComision = async (params) => {
    return await http_client(`${URL}/index-espera-apelacion-comision`, params, 'get');
}

const listadoSolicitudesApelacionComision = async (params) => {
    return await http_client(`${URL}/tab-apelaciones-comision`, params, 'get');
}

const listadoEnTribunalComision = async (params) => {
    return await http_client(`${URL}/tab-en-tribunal-comision`, params, 'get');
}


const getListadoFiltroUnidades = async () => await http_client(`${URL}/filtro-listado-unidades`);

// Acciones

const accionesPermitidas = async (params) => {
    return await http_client(`${URL}/fase`, params, 'get');
}

const cambiarFase = async (params) => {
    return await http_client(`${URL}/cambiar-fase`, params, 'post');
}

// Detalles

const getDetalleSolicitud = async (id) => {
    return await http_client(`${URL}/detalle-solicitud-despido/${id}`, {}, 'get');
}

const getDocumentosDespido = async (id, params) => {
    return await http_client(`${URL}/listado-documentos-despido/${id}`, params, 'get');
}

const getDocumentosApelacion = async (id, params) => {
    return await http_client(`${URL}/listado-documentos-apelacion/${id}`, params, 'get');
}

const showDocumento = async (id) => {
    return await http_client(`${URL}/show-documento-despido/${id}`, {}, 'get');
}

const deleteDocumento = async (id) => {
    return await http_client(`${URL}/delete-documento-general/${id}`, {}, 'delete');
}

const agregarDocGeneral = async (params) => {
    return await http_client(`${URL}/agregar-documento-general`, params, 'post');
}

const agregarDocApelacion = async (params) => {
    return await http_client(`${URL}/agregar-documento-apelacion`, params, 'post');
}

const agregarAmonestaciones = async (params) => {
    return await http_client(`${URL}/amonestacion-arreglo-add`, params, 'post');
}

const getDetalleResolucion = async (id) => {
    return await http_client(`${URL}/detalle-resolucion-despido/${id}`, {}, 'get');
}

const getActasResolucion = async (id, params) => {
    return await http_client(`${URL}/listado-actas-resolucion/${id}`, params, 'get');
}

// Crear

const getDatosPrimariosSolicitudDespido = async (id) => await http_client(`${URL}/data/`, id, 'get');

const getTipoDocumento = async () => await http_client(`${URL}/list-tipo-documentos`);

const getListadoUnidades = async () => await http_client(`${URL}/listado-unidades`);

const getListadoEstablecimientos = async () => await http_client(`${URL}/listado-establecimientos`);

const getPlazas = async (id) => await http_client(`${URL}/lista-plaza-empleado/${id}`);

const busquedaEmpleado = async (filtros) => await http_client(`${URL}/detalle-empleado`, filtros, 'get');

const obtenerDetallePlazaEmpleado = async (id) => await http_client(`${URL}/detalle-plaza-despido/${id}`);

const getAmonestaciones = async (params = {}) => await http_client(`api/v1/despidos/lista-amonestaciones`, params , 'get');

const postSolicitudDespido = async (data) => await http_client(`${URL}/solicitud`, data, 'post');

//solventar

const putActualizarAmonestaciones = async (data) => await http_client(`${URL}/actualizar-amonestaciones`, data, 'post');

const postAgregarActaResolucion = async (data) => await http_client(`${URL}/agregar-acta-resolucion`, data, 'post');

//historial amonestaciones

const getHistorialAmonestaciones = async (id_empleado_puesto, params) => http_client(`${URL}/historial-amonestaciones/${id_empleado_puesto}`, params, 'get');

export default {
    listadoSolicitudes,
    getListadoFiltroUnidades,
    listadoEsperaApelacion,
    listadoEnTribunal,
    listadoSolicitudesApelacion,
    listadoSolicitudesComision,
    listadoEsperaApelacionComision,
    listadoEnTribunalComision,
    listadoSolicitudesApelacionComision,
    getDetalleResolucion,
    getActasResolucion,
    getListadoUnidades,
    getListadoEstablecimientos,
    accionesPermitidas,
    agregarAmonestaciones,
    cambiarFase,
    postAgregarActaResolucion,
    getDetalleSolicitud,
    getDocumentosDespido,
    getDocumentosApelacion,
    showDocumento,
    deleteDocumento,
    agregarDocGeneral,
    agregarDocApelacion,
    getDatosPrimariosSolicitudDespido,
    getTipoDocumento,
    getPlazas,
    busquedaEmpleado,
    obtenerDetallePlazaEmpleado,
    getAmonestaciones,
    postSolicitudDespido,
    putActualizarAmonestaciones,
    getHistorialAmonestaciones
}
import { http_client } from "@/plugins/http_client";


function getParams(params) {
    return params ? new URLSearchParams(Object.entries(params)).toString() : "";
}

const cifradoPresupuestario = {
    listar: ({params, data}) => http_client('/api/mnt_cifrado/search?' + getParams(params), data, 'post'),
    crear: (body) => http_client('/api/mnt_cifrado', body, 'post'),
    editar: (id, body) => http_client(`api/cifrado/${id}`, body, 'put'),
    desactivar: (body) => http_client("/api/mnt_cifrado/" + body.id, {}, 'delete'),
    cambiarEstado: (id, body) => http_client(`api/cifrado/${id}/estado`, body, 'put'),
};

const getUnidadesPresupuestarias = async () => {
    return await http_client("api/lista-unidades-presupuestarias", {}, "get");
};



export default {
    cifradoPresupuestario,
    getUnidadesPresupuestarias
};

